import React, { useEffect, useMemo, useState } from 'react';

import 'reflect-metadata';

import { Provider, useSession } from 'next-auth/client';
import TagManager from 'react-gtm-module';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'app/styles/global.css';

import { useRouter } from 'next/router';
import { ToastContainer, Slide } from 'react-toastify';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  DefaultOptions,
  useQuery,
} from '@apollo/client';
import { GET_USER_INFO, HAS_ACCESS_TO_CALENDAR } from 'app/api/queries';

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  uri: `${publicRuntimeConfig.hostUrl}/api/graphql`,
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

export default function App({ Component, pageProps = {} }: any) {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider session={pageProps.session}>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          closeOnClick={false}
          pauseOnHover={false}
          draggable
          transition={Slide}
        />
        {Component.auth ? (
          <Auth>
            <Component {...pageProps} />
          </Auth>
        ) : (
          <Component {...pageProps} />
        )}
      </Provider>
    </ApolloProvider>
  );
}

App.getInitialProps = () => {
  return {};
};

function Auth({ children }: { children: JSX.Element }) {
  const router = useRouter();
  const [redirecting, setRedirecting] = useState(false);
  const [session, loading] = useSession();
  const { data, loading: loadingAccess } = useQuery(HAS_ACCESS_TO_CALENDAR, {
    fetchPolicy: 'network-only',
  });
  const { data: dataInfo } = useQuery(GET_USER_INFO);
  useEffect(() => {
    if (!loading && !loadingAccess) {
      if (!session || session?.error === 'RefreshAccessTokenError') {
        setRedirecting(true);
        router.push('/signin');
      } else if (!!data && !data?.hasAccessToCalendar) {
        setRedirecting(true);
        router.push('/google-calendar-error');
      } else if (dataInfo?.info && !dataInfo.info.onboardingCompleted) {
        setRedirecting(true);
        router.replace({
          pathname: '/onboarding',
        });
      } else {
        setRedirecting(false);
      }
    }
  }, [session, loading, data, loadingAccess]);
  const component = useMemo(
    () => (loading || loadingAccess || redirecting ? <></> : children),
    [loading, loadingAccess, redirecting, children],
  );
  return component;
}
