import { gql } from '@apollo/client';

export const GET_USER_TO_PROSPECTS = gql`
  query GetUserToProspects {
    userToProspects {
      id
      displayName
      meetings {
        id
        slug
        meetingName
        meetingEndsAt
      }
      prospect {
        id
        websiteDomain
        domainAliases
        name
        logoUrl
      }
    }
  }
`;
