import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    info {
      id
      email
      name
      dailyDigest
      actionItems
      productUpdates
      onboardingCompleted
    }
  }
`;

export const HAS_ACCESS_TO_CALENDAR = gql`
  query HasAccessToCalendar {
    hasAccessToCalendar
  }
`;
