import { gql } from '@apollo/client';

export const GET_MEETING = gql`
  query getMeeting($slug: String!) {
    meeting(slug: $slug) {
      id
      meetingName
      isFromCalendar
      slug
      meetingStartsAt
      meetingEndsAt
      structuredNotes
      freeFormNotes
      videoLink
      attendeesToMeeting {
        id
        myTeam
        status
        attendeeId
        attendee {
          id
          avatar
          email
          fullName
          bio
          geo
          employment
          socialNetworks
        }
      }
      actionItems {
        id
        title
        due
        completed
      }
      agenda {
        id
        title
        completed
      }
      userToProspect {
        id
        displayName
        status
        prospect {
          id
          slug
          websiteDomain
          name
          metrics
          logoUrl
          geo
          socialNetworks
        }
      }
    }
  }
`;

export const GET_MEETINGS = gql`
  query GetMeetings {
    meetings {
      id
      meetingName
      slug
      meetingStartsAt
      meetingEndsAt
      videoLink
      attendeesToMeeting {
        id
        status
        myTeam
      }
      actionItems {
        id
        title
        due
        completed
      }
      agenda {
        id
      }
      userToProspect {
        displayName
        prospect {
          websiteDomain
          name
          logoUrl
        }
      }
    }
  }
`;
export const SYNC_MEETINGS = gql`
  query SyncMeetings {
    syncMeetings
  }
`;

export const GET_PREVIOUS_MEETING = gql`
  query getPreviousMeetings($slug: String!) {
    previous(slug: $slug) {
      id
      meetingName
      slug
      meetingStartsAt
      actionItems {
        id
        title
        due
        completed
      }
    }
  }
`;

export const GET_RECENT_MEETINGS = gql`
  query GetRecentMeetings {
    recent {
      meetingName
      id
      slug
      meetingEndsAt
      userToProspect {
        displayName
        prospect {
          websiteDomain
          name
          logoUrl
        }
      }
    }
  }
`;
