import { gql } from '@apollo/client';

export const GET_ACTION_ITEMS = gql`
  query GetActionItems(
    $range: RangeInput!
    $all: Boolean!
    $showAll: Boolean!
  ) {
    actionItems(range: $range, all: $all, showAll: $showAll) {
      data {
        id
        title
        completed
        order
        due
        meeting {
          slug
          userToProspect {
            displayName
            prospect {
              websiteDomain
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_WEEK_ACTION_ITEMS = gql`
  query GetWeekActionItems {
    week {
      id
      title
      completed
      order
      due
      meeting {
        slug
        userToProspect {
          displayName
          prospect {
            websiteDomain
            name
          }
        }
      }
    }
  }
`;

export const GET_OVERDUE_ACTION_ITEMS = gql`
  query GetOverdueActionItems {
    overdue {
      id
      title
      completed
      order
      due
      meeting {
        slug
        userToProspect {
          displayName
          prospect {
            websiteDomain
            name
          }
        }
      }
    }
  }
`;
